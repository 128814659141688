<template>
  <v-card>
    <v-container>
      <v-card-title class="mt-n3">
        <span class="primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-n2">
          <!-- numero de asiento -->
          <v-col cols="4" md="4" class="py-0">
            <v-text-field
              type="text"
              outlined
              dense
              filled
              readonly
              label="Número de asiento"
              v-model="numeroAsiento"
              autocomplete="off"
              disabled
            ></v-text-field>
          </v-col>
          <!-- empresa -->
          <v-col cols="4" md="4" class="py-0">
            <v-text-field
              type="text"
              outlined
              dense
              filled
              readonly
              label="Empresa"
              v-model="empresa"
              autocomplete="off"
              disabled
            ></v-text-field>
          </v-col>
          <!-- ejecricio -->
          <v-col cols="4" md="4" class="py-0">
            <v-text-field
              type="text"
              outlined
              dense
              filled
              readonly
              label="Ejercicio"
              v-model="ejercicio"
              autocomplete="off"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-n2 mb-n6">
          <!-- fecha asiento -->
          <v-col cols="3" md="3" class="py-0">
            <v-text-field
              type="text"
              outlined
              dense
              filled
              readonly
              label="Fecha"
              v-model="fechaAsiento"
              autocomplete="off"
              disabled
            ></v-text-field>
          </v-col>
          <!-- observaciones -->
          <v-col cols="9" md="9" class="py-0">
            <v-text-field
              type="text"
              outlined
              dense
              filled
              readonly
              label="Observación"
              v-model="observacion"
              autocomplete="off"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- tabla -->
        <v-data-table
          :headers="headersAsientosComp"
          :items="itemsAsientosComp"
          height="100%"
          fixed-header
          item-key=""
          class="elevation-1"
          :loading="isLoadingTable"
          hide-default-footer
        >
          <template v-slot:[`item.debe`]="{ item }">
            <span v-if="item.debeHaber === 'D'">{{ item.importe }}</span>
          </template>
          <template v-slot:[`item.haber`]="{ item }">
            <span v-if="item.debeHaber === 'H'">{{ item.importe }}</span>
          </template>
        </v-data-table>
        <v-row class="mt-3">
          <!-- total de gastos -->
          <v-col cols="3" md="3" class="py-0 mr-16">
            <v-text-field
              type="text"
              outlined
              dense
              filled
              readonly
              label="Total gastos"
              prepend-inner-icon="mdi-currency-usd"
              v-model="totalGastos"
              autocomplete="off"
              disabled
            ></v-text-field>
          </v-col>
          <!-- total debe -->
          <v-col cols="3" md="3" class="py-0 ml-16">
            <v-text-field
              type="text"
              outlined
              dense
              filled
              readonly
              label="Debe"
              prepend-inner-icon="mdi-currency-usd"
              v-model="totalDebe"
              autocomplete="off"
              disabled
            ></v-text-field>
          </v-col>
          <!-- total haber -->
          <v-col cols="3" md="3" class="py-0">
            <v-text-field
              type="text"
              outlined
              dense
              filled
              readonly
              label="Haber"
              prepend-inner-icon="mdi-currency-usd"
              v-model="totalHaber"
              autocomplete="off"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mt-n8">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "DetalleAsientosCblesComp",
  props: {
    asientosCblesId: {
      type: Number,
    },
  },
  created() {
    this.setDatosAsientos();
  },
  data() {
    return {
      formEditTitle: "Detalle del asiento contable",
      numeroAsiento: null,
      empresa: null,
      ejercicio: null,
      fechaAsiento: null,
      observacion: null,
      totalGastos: null,
      totalDebe: null,
      totalHaber: null,
      isLoadingTable: false,
      itemsAsientosComp: [],
      headersAsientosComp: [
        {
          text: "Código",
          value: "codigoCta",
          sortable: false,
          align: "start",
        },
        {
          text: "Nombre de cuenta",
          value: "nombreCta",
          sortable: false,
          align: "start",
        },
        {
          text: "Debe",
          value: "debe",
          sortable: false,
          align: "end",
        },
        {
          text: "Haber",
          value: "haber",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      GetDatosAsientosCblesComp: "proveedores/GetDatosAsientosCblesComp",
    }),
    async setDatosAsientos() {
      const response = await this.GetDatosAsientosCblesComp(
        this.asientosCblesId
      );
      this.numeroAsiento = response.numeroAsiento;
      this.empresa = response.empresaNom;
      this.ejercicio = response.ejercicioNom;
      this.fechaAsiento = response.fecha;
      this.observacion = response.descripcion;
      this.totalGastos = response.totalGastos;
      this.totalDebe = response.totalDebe;
      this.totalHaber = response.totalHaber;
      this.itemsAsientosComp = response.asientos;
    },
    closeModal() {
      this.$emit("closeAsientosModal");
    },
  },
};
</script>

<style scoped>
</style>