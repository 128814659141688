<template>
  <v-card>
    <v-card-title class="ml-4 pt-2">
      <span class="primary--text">{{ title }}</span>
    </v-card-title>
    <v-card-text class="py-0">
      <v-col cols="12">
        <v-data-table
          :loading="loadingTable"
          :headers="headersDetalleLiq"
          :items="itemsDetalleLiq"
          height="100%"
          class="elevation-3"
          :expanded.sync="expanded"
          show-expand
          hide-default-footer
          single-expand
          item-key="liqProcVenId"
        >
          <!-- Menu desplegable -->
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="handleExpansion(item, isExpanded)"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  >{{
                    isExpanded ? "mdi-account-check" : "mdi-account"
                  }}</v-icon
                >
              </template>
              <span>Usuarios</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Fecha alta:</strong>
              {{ item.fechaAlta }}
              <strong style="padding-left: 105px">Usuario alta:</strong>
              {{ item.usuAlta }}
            </td>
          </template>
        </v-data-table>
      </v-col>
      <v-row class="mb-n2 mt-3 mr-0 ml-0">
        <v-col cols="4" sm="4" md="4" class="py-0">
          <v-text-field
            type="text"
            outlined
            dense
            filled
            readonly
            label="Proveedor"
            v-model="itemToLiqVen.proveedor"
            autocomplete="off"
          ></v-text-field>
        </v-col>
        <v-col cols="4" sm="4" md="4" class="py-0">
          <v-text-field
            type="text"
            outlined
            dense
            filled
            readonly
            label="Tipo comprobante"
            v-model="itemToLiqVen.tipoComprobante"
            autocomplete="off"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="2" md="2" class="py-0">
          <v-text-field
            type="text"
            outlined
            dense
            filled
            readonly
            label="Fecha"
            v-model="itemToLiqVen.fecha"
            autocomplete="off"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mb-n7 ml-4">
        <v-col cols="12" sm="1" md="1" class="py-0 p-0">
          <v-text-field
            type="text"
            outlined
            dense
            filled
            readonly
            label="Letra"
            v-model="comprobanteLetra"
            autocomplete="off"
          ></v-text-field>
        </v-col>
        <v-col class="py-0 pl-2 pr-2" cols="1" md="1" sm="1" xs="1">
          <v-text-field
            type="text"
            outlined
            dense
            filled
            readonly
            label="Sucursal"
            v-model="comprobanteSuc"
            autocomplete="off"
          ></v-text-field>
        </v-col>
        <v-col class="py-0 pl-0" cols="12" md="2">
          <v-text-field
            type="text"
            outlined
            dense
            filled
            readonly
            label="Número"
            v-model="comprobanteNum"
            autocomplete="off"
          ></v-text-field>
        </v-col>
        <v-col class="py-0 pl-0" cols="12" md="1">
          <v-text-field
            type="text"
            outlined
            dense
            filled
            readonly
            label="Proceso"
            v-model="procesoNum"
            autocomplete="off"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pt-0 mr-8 pb-n2">
      <v-spacer></v-spacer>
      <v-btn outlined @click="closeModal"> Cerrar </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "DetalleLiquidacionVendedores",
  props: {
    mProvId: {
      type: Number,
      required: true,
      default: -1,
    },
    itemToLiqVen: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    title: "Comprobante de liquidación",
    proveedorNom: "",
    proveedorCod: null,
    letraComp: null,
    numeroComp: null,
    sucursalComp: null,
    tipoComp: null,
    fechaComp: null,
    headersDetalleLiq: [
      {
        text: "Nombre fórmula",
        sortable: false,
        value: "formulaNom",
      },
      {
        text: "Contenido",
        sortable: false,
        value: "formulaContenido",
      },
      {
        text: "Valorización",
        sortable: false,
        align: "left",
        value: "formulaValorizada",
      },
      {
        text: "Total",
        sortable: false,
        align: "left",
        value: "importeTotal",
      },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
        align: "end",
      },
    ],
    expanded: [],
    itemsDetalleLiq: [],
    loadingTable: false,
    comprobanteLetra: null,
    comprobanteNum: null,
    procesoNum: null,
    comprobanteSuc: null,
  }),
  created() {
    this.setDetalleLiquidacionVend();
  },
  methods: {
    ...mapActions({
      getLiquidacionVendedores: "proveedores/getLiquidacionVendedores",
    }),
    async setDetalleLiquidacionVend() {
      this.loadingTable = true;
      try {
        const response = await this.getLiquidacionVendedores(this.mProvId);
        if (response) {
          response.importeTotal = this.itemToLiqVen.importeTotal;
          this.itemsDetalleLiq.push(response);
          this.procesoNum = response.liqProcVenId;
        }
      } catch {}
      const str = this.itemToLiqVen.comprobante;
      this.comprobanteLetra = str.substring(0, 1).trim();
      this.comprobanteSuc = str.substring(2, 7).trim();
      this.comprobanteNum = str.substring(str.indexOf("-") + 2).trim();
      this.loadingTable = false;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    closeModal() {
      this.itemsDetalleLiq = [];
      this.$emit("closeModal");
    },
  },
};
</script>